@use "../../scss/variables" as *;

input {
    //border: 0;
    font-weight: bold;
    background-color: $black;
    padding: 0.5rem 1.5rem;
    //font-size: 1rem;
    border-radius: $border-radius;
    color: $txt-color;
    font-family: $font-family;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(1, 1, 1);
    font-size: 1rem;
    font-weight: lighter;
    opacity: 0.5; /* Firefox */
  }
