@use "../../scss/" as *;

.movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 3rem;

    @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    @include mobile{
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }

    &__loadmore {
        text-align: center;
    }
    &__loadingmore {
        justify-content: center;
    }
}

.movie-search {
    display: flex;
    width: 100%;
    max-width: 800px;
    
    input {
        margin: auto;
        min-width: 70%;
        background-color: rgb(225, 236, 236);
        color: #000;
    }

    button {
        margin-left: 10px;
        right: 0;
      
        top: 2px;

        @include mobile {
            right: 1px;
            top: 1px;
        }
    }
}

