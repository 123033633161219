@use "../scss/" as *;
.playcard{
    // max-width: 500px;
    // min-width: 300px;
    // max-height: 700px;
     padding: 20px;
    // height: 60%;
    // margin: 100px auto;
    background-color: #ffffff;
    border-radius: 25px;
    // padding-bottom: 2rem;
}
.containerdiv {
    
    position: relative;
    overflow: hidden;
    min-height: 480px;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .reportinput {
        margin: auto;
        min-width: 70%;
        min-height: "70px";
        background-color: rgb(225, 236, 236);
        color: #000;
    }