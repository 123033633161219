.seasoncard{
    // max-width: 500px;
    // min-width: 300px;
    // max-height: 700px;
     padding: 20px;
    // height: 60%;
    margin:10px;
    background-color: #ffffff;
    border-radius: 25px;
    padding-bottom: 2rem;
}
.butn{
    margin: 5px;
    font-size: medium;
}
.botn{
    margin: 5px;
    font-size: medium;
    color: black;
    border-color: black;
}