@use "../../scss/breakpoint" as *;

.movie-list {
    .swiper-slide {
        width: 13%;

        @include tablet {
            width: 30%;
        }

        @include mobile {
            width: 33%;
        }
    }
}
